import React from 'react';
import { graphql, navigate } from 'gatsby';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import { IBlogPostPreview } from '../queries-intefaces/posts';
import loadable from '@loadable/component';

class RootIndex extends React.Component {
  render() {
    const Layout = loadable(() => import ('../components/layout'));
    const Home = loadable(() => import ('../components/home/home'));

    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts: Array<IBlogPostPreview> = get(
      this,
      'props.data.allContentfulBlogPost.edges'
    );
    const [author] = get(this, 'props.data.allContentfulPerson.edges');
    const cryptoCards = get(this, 'props.data.allContentfulJourneyCards.nodes');
    const homeData = get(this, 'props.data.allContentfulHomePage.nodes');
    const [footerData] = get(this, 'props.data.allContentfulFooterSection.nodes');
    const [headerData] = get(this, 'props.data.allContentfulHeaderSection.nodes');

    let pathname = {};
    if (typeof window !== 'undefined') {
      pathname = window.location.pathname.split('/');
    }
    
    if(pathname[1] == ''){
      const value = pathname[1] == '' ? 'btc' : 'eth';
      navigate(`/${value}`);
    }

    homeData.buyCardCoins = get(this, 'props.data.allContentfulCurrency.nodes');
    return (
      <Layout className="violet-style" footer={footerData} header={headerData}>
        <div>
          <Helmet title={siteTitle} />
          {/* <Hero data={author.node} /> */}

          <Home
            homeData={homeData}
            cryptoCards={cryptoCards}
            blogPosts={homeData[0].latestBlogPosts}
          />

          {/* <div className="wrapper container mt-4 mb-5">
            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                );
              })}
            </ul>
          </div> */}
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHomePage {
      nodes {
        title
        subTitle {
          childMarkdownRemark {
            html
          }
        }
        buyCardExchangeSubtitle
        learnTitle
        learnTextButton
        learnSectionTitle
        learnDescription {
          childMarkdownRemark {
            html
          }
        }
        learnLinkButton
        learningResources {
          image {
            file {
              url
            }
          }
          type
        }
        journeyTitle
        journeySubtitle
        exploreCardItems {
          description
          title
          textButton
          image {
            file {
              url
            }
          }
        }
        lineButtonTextLeft
        lineButtonTextRight
        latestBlogPosts {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags {
            code
            name
          }
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              src
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          textToShareLinks
        }
        buyCardExchangeEthereum
        buyCardExchangeBitcoin
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags {
            code
            name
          }
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              src
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              tracedSVG
              src
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allContentfulJourneyCards {
      totalCount
      nodes {
        id
        createdAt
        enabled
        linkText
        linkUrl
        title
        paragraph {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulCurrency {
      nodes {
        code
        name
        description
        displayCurrency
      }
    }
    allContentfulFooterSection(limit: 1){
      nodes {
        icon {
          id
          fluid {
            src
            base64
          }
          file {
            url
          }
        }
        description {
          childMarkdownRemark{
            html
          }
        }
        copyright {
          childMarkdownRemark{
            html
          }
        }
        socialMediaLinks{
          title
          linkUrl
          showLink
        }
        links{
          title
          linkUrl
          showLink
        }
      }
    }
    allContentfulHeaderSection(limit: 1){
      nodes {
        title
        leftLinks{
          title
          linkUrl
          showLink
        }
        rightLinks{
          title
          linkUrl
          showLink
        }
      }
    }
  }
`;
